import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const WaitlistPopUp = ({ setShowWaitlist, showWaitlist }) => {
  const emailRef = useRef();

  const [displayText, setDisplayText] = useState(
    "Thanks for joining our wait list!"
  );
  const [isSubmitted, setSubmitted] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const handleVerification = (response) => {
    // The response parameter contains the reCAPTCHA token
    if (response) {
      setVerified(true);
    }
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

  emailjs.sendForm('service_gkwgv31', 'template_ca8220d', form.current,'MnHJgiYop2G5TrSd6')
    .then((result) => {
        console.log(result.text);
        setSubmitted(true);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setShowWaitlist(false);
  };

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains("waitlist-popup")) {
      setShowWaitlist(false);
    }
  };

  if (!showWaitlist) return;

  return (
    <section
      onClick={handleBackgroundClick}
      className="waitlist-popup w-screen px-5 sm:px-9 md:px-[136px] h-screen fixed top-0 left-0 flex justify-center items-center z-50"
    >
      <div className="w-[1143px] bg-[#EDDAFF] py-8 rounded-[10px] flex flex-col items-center justify-center">
        <h1 className="text-black font-bold text-3xl sm:text-[46px] sm:mt-11">
          Launching Soon!
        </h1>
        <h2 className="text-lg sm:text-4xl font-medium text-center">
          Join our waiting list to be notified
        </h2>
        <p className="text-center text-[#912FB2] text-sm font-bold mb-3 sm:mt-4 sm:mb-8">
          Sign up to be the first to know when we launch
        </p>

        <form
          ref={form}
          onSubmit={sendEmail}
          className="flex gap-6 w-full flex-col sm:flex-row px-3 sm:px-20 lg:px-[120px] sm:mb-11"
        >
          <div className="flex flex-col gap-2 w-full">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Enter Name"
              required
              className="outline-none border border-solid border-[#ABABAB] rounded-lg w-full text-base font-medium placeholder-[#ABABAB]"
            />
            <input
              type="email"
              name="email"
              id="email"
              required
              placeholder="Enter Email"
              className="outline-none border border-solid border-[#ABABAB] rounded-lg w-full text-base font-medium placeholder-[#ABABAB]"
            />

            <div>
              <ReCAPTCHA
                sitekey="6Lfzd5MpAAAAAJTxzEESE7guohKaTFdogVA-YFrV"
                onChange={handleVerification}
              />
            </div>
          </div>

          <div className="self-end">
            <button
              type="submit"
              disabled={!isVerified}
              className={`${
                isVerified ? "bg-[#5E42D9]" : "bg-[#898787]"
              } px-4 py-2  text-white rounded-[10px] w-[141px] text-base font-bold`}
            >
              Notify Me
            </button>

            {isSubmitted && (
              <div className="text-lg cursor-pointer   items-center font-semibold text-blue-700">
                <p className="text-sm p-2">{displayText}</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default WaitlistPopUp;
