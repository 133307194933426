import React, { useEffect, useState } from 'react'
import GlobalApi from '../Api/GlobalApi';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { Spinner } from 'flowbite-react';


const Terms = () => {
 const [terms, setTerms] = useState([]);
 const [isLoading, setIsLoading] = useState(false);


 useEffect(()=>{
    setIsLoading(true)
    getTerm();
 },[]);
 const getTerm =()=>{
     GlobalApi.getTermsAndConditions().then(resp=>{
        setTerms(resp.terms)
        setIsLoading(false)

     })
 }
  return (
    <div style={{marginTop:"120px"}}>
          <div>
          <div>
                {isLoading && (
              <div className='p-2 text-5xl   text-center'>
                  <Spinner color="purple" aria-label="Purple spinner example" size="xl"/>
              </div>
            )}
                </div>
               {terms.map((term)=>(
                  <div key={term.id}>
                      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-1 p-4'>
                          <div className='text-2xl font-bold text-center'>
                             {term?.name}
                          </div>
                          <div className='pt-16 text-lg lg:pl-12 mb-20 lg:text-1xl leading-10 ' >
                             <RichText  content={term?.description.raw.children}  />
                          </div>
                      </div>  
                  </div>
               ))}
          </div>
     </div>
  )
}

export default Terms