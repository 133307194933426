const data = [
    {
        question: "How does P2P foreign exchange work?",
        answer: "Users indicate the currency they have and the currency they need. Our platform matches users seeking opposite currencies, enabling them to agree upon exchange rates directly and conclude transaction."
    },
    {
        question: "Can I set my own exchange rate on the P2P exchange?",
        answer: " Yes, users have the flexibility to set their own exchange rates. This allows for personalized transactions and negotiations that align with individual preferences."
    },
    {
        question: "How does payment of international school fees work on your platform?",
        answer: "Initiate a school fee payment on the app and upload your payment instruction. Users can manage multiple institutions in one place, receive automated payment reminders, make secure and timely transactions." 
    },
    {
        question: "How quickly can international money transfers be completed?",
        answer: "Our platform prioritizes swift transfers to ensure funds reach their destination instantly."
    },
    {
        question: "Are payments for international school fees secure?",
        answer: "Absolutely. We ensure secure transactions with advanced encryption protocols. Your financial data and payments are protected  throughout the process" 
       
    },
    {
        question: "How secure are P2P foreign exchange transactions?",
        answer: "We prioritize security with robust encryption and verification measures. Users can communicate securely within our platform, and transactions occur only upon agreement by both parties." 
        
    },
    {
        question: "What are the benefits of using P2P foreign exchange?",
        answer: "Benefits include competitive rates, transparency in transactions, and the elimination of middlemen, resulting in cost savings on fees."
    },
]

export default data;