import React, { useEffect, useState } from "react";
import WaitlistButton from "../WaitlistButton";
import WaitlistPopUp from "../WaitlistPopUp";
import "./top.css"
import CurrencyConverter from "./CurrencyConverter";
import InfiniteScroll from 'react-infinite-scroll-component';

const TopMost = () => {
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [rates, setRates] = useState([]);
  const [ratesFetched, setRatesFetched] = useState(false);


  const getRates = async () => {
    // fetch the data from API
    const response = await fetch(
      "https://v6.exchangerate-api.com/v6/2e99ae87ccbe134e93e33626/latest/USD"
    ).then((response) => response.json());
    console.log(response);
    // save the rates in the state
    if (response.result === "success") {
      setRates(response.conversion_rates);
      setRatesFetched(true);
    }
  };
  useEffect(() => {
    getRates();
  }, []);


  return (
    <div >
      <div className="bg-purple-50 " id="render">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 min-h-lvh   md:p-14 lg:pt-28 p-10  ">
          <div className="circle my-auto">
            <p className="lg:text-5xl lg:text-start text-3xl text-center font-bold">
              Your all-in-one <br />
              Financial Companion.
            </p>
            <div className="">
              <p className="mt-10 lg:text-2xl md:text-center lg:text-start font-semibold  sm:text-nowrap text-md text-gray-600 text-center">
                Experience the ease of direct peer-to-peer currency exchange,
                <br className="hidden md:block lg:block" />
                swift money transfers, and effortless payments for <br className="hidden md:block lg:block" />{" "}
                international education fees-all from one secure platform.
              </p>
              <p className="mt-10 flex items-center justify-center lg:justify-start ">
                <WaitlistButton setShowWaitlist={setShowWaitlist} />
              </p>
            </div>
            <div className="mt-10 mb-10 flex items-center justify-center lg:justify-start  ">

              <img
                src="/images/appstore2024.png"
                alt="app store"
                className="w-32 lg:w-48"

              />
              <img
                src="/images/google_2024.png"
                alt="google store"
                className="w-32 ml-2 lg:w-48"
              />
            </div>
          </div>
          <div className="my-auto">
            <img
              src="/images/newhero.png"
              alt="hero"

              className=" lg:ml-52  mx-auto w-4/6"
            />
          </div>
        </div>
       

      </div>

      {showWaitlist && (
        <WaitlistPopUp
          setShowWaitlist={setShowWaitlist}
          showWaitlist={showWaitlist}
        />
      )}


    
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-4">
        <div className="text-md  md:text-2xl font-semibold text-gray-600 mt-4 lg:text-start text-center">
          <p>Connect, buy and sell your preferred currencies</p>
        </div>
        <div className="flex items-center justify-center mt-4 ">
          <img src="/images/Britain.png" alt="britain" className="mr-3" style={{ width: "60px", borderRadius: "10px" }} />
          <img src="/images/Nigeria.png" alt="nigeria" className="mr-3" style={{ width: "60px", borderRadius: "10px" }} />
          <img src="/images/Canada.png" alt="canada" className="mr-3" style={{ width: "60px", borderRadius: "10px" }} />
          <img src="/images/Ghana.png" alt="ghana" className="mr-3" style={{ width: "60px", borderRadius: "10px" }} />
          <img src="/images/America.png" alt="america" className="mr-3" style={{ width: "60px", borderRadius: "10px" }} />
        </div>
      </div>
    </div>
  );
};

export default TopMost;
