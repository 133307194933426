import React from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";

const Benefits = () => {
  const benefits = [
    "Instant Payments",
    "Eliminate Middlemen",
    "Empower Users",
    "Determine Your Own Rate",
    "Engage and Connect",
  ];

  return (
    <section className="md:py-[107px] grid md:grid-cols-2">
      <div className="benefit-bg px-10 lg:px-[120px] py-7 sm:py-24 flex flex-col justify-center">
        <h2 className="text-3xl sm:text-[46px] text-white font-bold leading-none">
          Benefits of using us?
        </h2>
        <ul className="py-4 flex flex-col gap-3">
          {benefits.map((benefit) => (
            <li className="text-base sm:text-[22px] text-white flex gap-2 items-center">
              <HiOutlineCheckCircle />
              <p>{benefit}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="py-[50px] flex justify-center items-center max-md:px-10">
        <div className="relative">
          <img
            src="/images/benefits_icon1.png"
            alt="benefits icon1"
            className="absolute -right-16 top-[85px] max-lg:-right-8 max-lg:top-8 max-[1200px]:-right-[30px] max-[1200px]:top-[30px] max-[1200px]:w-[70px]"
          />
          <img
            src="/images/benefits.png"
            alt="smiling female"
            className="w-[442px] max-sm:w-[250px] max-[1200px]:w-[300px]"
          />
          <img
            src="/images/benefits_icon2.png"
            alt="benefits icon2"
            className="absolute -left-[40px] sm:-left-[62px] -bottom-2 max-[1200px]:w-[200px] max-lg:w-[200px]"
          />
        </div>
      </div>
    </section>
  );
};

export default Benefits;
