import { useState } from 'react';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  return (
    <div>
      <nav className="w-full bg-white fixed top-0 left-0 right-0 z-10 border-b-2 border-b-indigo-100">
        <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              {/* LOGO */}
              <Link to="/">
              <img src="/images/Logo-header.png" width={100} height={100} alt="logo" />
              </Link>
              {/* HAMBURGER BUTTON FOR MOBILE */}
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <img src="/images/close.png" width={30} height={30} alt="logo" />
                  ) : (
                    <img
                      src="/images/hamburger.png"
                      width={30}
                      height={30}
                      alt="logo"
                      className="focus:border-none active:border-none"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? 'p-12 md:p-0 block' : 'hidden'
              }`}
            >
              <ul className="h-screen md:h-auto items-center pt-4 justify-center md:flex ">
                <li className="pb-6 text-1xl cursor-pointer font-semibold text-black py-2 md:px-6 text-center border-b-2 md:border-b-0  hover:bg-blue-600   md:hover:text-blue-600 md:hover:bg-transparent">
                  <a href='#services' onClick={() => setNavbar(!navbar)}>
                     Services
                  </a>
                </li>
                <li className="pb-6 text-1xl cursor-pointer font-semibold text-black py-2 px-6 text-center  border-b-2 md:border-b-0  hover:bg-blue-600  md:hover:text-blue-600 md:hover:bg-transparent">
                  <a href='#about-us' onClick={() => setNavbar(!navbar)}>
                      About Us 
                  </a>
                </li>
                <li className="pb-6 text-1xl cursor-pointer font-semibold text-black py-2 px-6 text-center  border-b-2 md:border-b-0  hover:bg-blue-600   md:hover:text-blue-600 md:hover:bg-transparent">
                  <a href="#faqs" onClick={() => setNavbar(!navbar)}>
                       FAQS
                  </a>
                </li>
                <li className="pb-6 text-1xl font-semibold block md:hidden text-black py-2 px-6 text-center">
                  <Link to="/contact" onClick={() => setNavbar(!navbar)}>
                   <button className="bg-blue-900 hover:bg-blue-700 text-white font-sm py-2 px-4 rounded-md">
                       Contact Us
                    </button>
                  </Link>
                </li>
               
              </ul>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? 'p-12 md:p-0 block' : 'hidden'
              }`}
            >
              <ul className="h-screen md:h-auto items-center pt-4 justify-center md:flex ">
              <li className="pb-6 text-1xl font-semibold text-black py-2 px-6 text-center">
                  <Link to="/contact" onClick={() => setNavbar(!navbar)}>
                   <button className="bg-blue-900 hover:bg-blue-700 text-white font-sm py-2 px-4 rounded-md">
                       Contact Us
                    </button>
                  </Link>
                </li>
               
              </ul>
            </div>
          </div>
        
        </div>
      </nav>
    </div>
  );
}

export default Navbar