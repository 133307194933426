import React from "react";

const WaitlistButton = ({ setShowWaitlist }) => {
  return (
    <button
      type="button"
      className="px-4 lg:px-8 py-2 lg:py-4 text-lg text-white font-bold rounded-[10px] bg-indigo-700 hover:text-sm  hover:bg-indigo-400 "
      onClick={() => setShowWaitlist(true)}
    >
      Join Waitlist
    </button>
  );
};

export default WaitlistButton;
