import React from 'react'

const GetStartedButton = ({ setShowWaitlist }) => {
  return (
    <div>
         <button className="bg-[#231BB5] text-white py-2 px-4 rounded mr-4"
          onClick={() => setShowWaitlist(true)}
         >
                  Get Started
          </button>
       </div>
  )
}

export default GetStartedButton