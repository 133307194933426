import React, { useState } from "react";
import GetStartedButton from "./GetStartedButton";
import WaitlistPopUp from "./WaitlistPopUp";

const OurServices = () => {
  const [showWaitlist, setShowWaitlist] = useState(false);
  const handleLearnMoreClick = () => {
    const serviceOne = document.getElementById("service_1");
    const serviceDetailsOne = document.getElementById("service_details_1");
    if (serviceOne && serviceDetailsOne) {
      serviceOne.style.display = "none";
      serviceDetailsOne.style.display = "block";
    }
  };
  const handleReturnClick = () => {
    const serviceOne = document.getElementById("service_1");
    const serviceDetailsOne = document.getElementById("service_details_1");
    if (serviceOne && serviceDetailsOne) {
      serviceOne.style.display = "flex";
      serviceDetailsOne.style.display = "none";
    }
  };

  const handleLearnMoreClick2 = () => {
    const serviceTwo = document.getElementById("service_2");
    const serviceDetailsTwo = document.getElementById("service_details_2");
    if (serviceTwo && serviceDetailsTwo) {
      serviceTwo.style.display = "none";
      serviceDetailsTwo.style.display = "block";
    }
  };
  const handleReturnClick2 = () => {
    const serviceTwo = document.getElementById("service_2");
    const serviceDetailsTwo = document.getElementById("service_details_2");
    if (serviceTwo && serviceDetailsTwo) {
      serviceTwo.style.display = "flex";
      serviceDetailsTwo.style.display = "none";
    }
  };

  const handleLearnMoreClick3 = () => {
    const serviceThree = document.getElementById("service_3");
    const serviceDetailsThree = document.getElementById("service_details_3");
    if (serviceThree && serviceDetailsThree) {
      serviceThree.style.display = "none";
      serviceDetailsThree.style.display = "block";
    }
  };
  const handleReturnClick3 = () => {
    const serviceThree = document.getElementById("service_3");
    const serviceDetailsThree = document.getElementById("service_details_3");
    if (serviceThree && serviceDetailsThree) {
      serviceThree.style.display = "flex";
      serviceDetailsThree.style.display = "none";
    }
  };

  return (
    <div className="px-10 lg:px-10 py-12" id="services">
      <div className="text-center lg:mb-12 mb-4">
        <h2 className="text-3xl lg:text-5xl text-[#452FAA] font-bold">
          Our Services
        </h2>
      </div>
      <div
        className="flex flex-col lg:flex-row lg:justify-between -mx-4 lg:mx-10"
        id="service_1"
      >
        <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0" id="exchange">
          <div className="h-full text-left my-5 lg:my-20 relative" >
            <h3 className="text-2xl lg:text-5xl lg:text-start text-left md:text-center text-[#452FAA] font-bold fs-2 mb-5 lg:mb-10">
              Buy and Sell Currencies
            </h3>
            <p className="mb-5 lg:mb-8 text-xl font-semibold text-gray-600">
              We believe in connecting people worldwide through fair and secure
              currency exchange. Our peer-to-peer (P2P) foreign exchange service
              offers a revolutionary...
            </p>
            <button
              href="#"
              id="learn_more_1"
              className="text-[#452FAA]  font-bold"
              onClick={handleLearnMoreClick}
            >
              Learn More
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4 mb-3 md:mb-5 lg:mb-0">
          <div className="h-full text-center relative">
            <img
              src="/images/Buy-and-sell-currency.jpg"
              alt="Service Image"
              className="f w-full rounded-3xl mx-auto"
            />
          </div>
        </div>
      </div>

      <div className="hidden mt-8" id="service_details_1">
        <div className="bg-[#912FB2] text-white p-4 lg:p-8 ">
          <div className="flex items-start mb-5 lg:mb-8">
            <button
              className="bg-[#912FB2] text-white p-2  mr-4"
              onClick={handleReturnClick}
              id="return_1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </button>
            <div>
              <h3 className="text-2xl lg:text-3xl font-bold">
                Buy and Sell Currencies
              </h3>
              <div className="my-8 w-full lg:w-1/2">
                <p className="text-gray-200">
                  We believe in connecting people worldwide through fair and
                  secure currency exchange. Our peer-to-peer (P2P) foreign
                  exchange service offers a revolutionary way to Buy and Sell
                  currencies directly between individuals, making your
                  transactions seamless and cost-effective. Ready to exchange
                  currencies with ease and confidence? Sign up today and start
                  your journey towards seamless global transactions
                </p>
              </div>
              <div className="flex">
                <GetStartedButton setShowWaitlist={setShowWaitlist}  />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse lg:flex-row w-full pt-4">
          <div className="flex items-center w-full lg:w-1/2 justify-center">
            <img
              src="/images/buy_and_sell_mockup.png"
              className=""
              alt="send_money"
            />
          </div>
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl my-5 font-bold">How it works</h2>
            <div className="mb-5">
              <div className="bg-[#FDEBFF] rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Currency Pair Selection
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Select the currencies you possess and those you are seeking
                    to exchange. Our platform supports a wide range of global
                    currencies.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF] rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">Set Your Own Rate</div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Take control by fixing your desired exchange rate at which
                    you are willing to exchange. Whether you seek competitive
                    rates or specific preferences, it's entirely up to you.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF]  rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Engage and Connect
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Initiate communication with potential partners via our
                    secure messaging system. Our platform intelligently matches
                    you with individuals seeking the opposite currency exchange.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF]  rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Exchanged Confirmation
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Upon successful completion, funds are released, and both
                    parties can confirm the transaction, building a transparent
                    transaction history.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col-reverse lg:flex-row lg:justify-between -mx-4 lg:mx-10 mt-14 md:mt-20"
        id="service_2"
      >
        <div className="w-full lg:w-1/2 px-4 mb-3 md:mb-8 lg:mb-0" id="tuition">
          <div className="h-full text-center relative">
            <img
              src="/images/Tuition-fee-2.jpg"
              alt="Service Image"
              className="f w-full rounded-3xl mx-auto"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
          <div className="h-full text-left my-5 lg:my-20 relative">
            <h3 className="text-2xl lg:text-5xl text-[#452FAA] lg:text-start text-left md:text-center font-bold fs-2 mb-5 lg:mb-10">
              Pay Tuition Fees
            </h3>
            <p className="mb-5 lg:mb-8 text-xl font-semibold text-gray-600">
              Invest in education without borders. Join our platform and
              experience the convenience and peace of mind that comes with
              managing international...
            </p>
            <button
              href=""
              className="text-[#452FAA]  font-bold"
              onClick={handleLearnMoreClick2}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>

      <div className="hidden mt-8" id="service_details_2">
        <div className="bg-[#912FB2] text-white p-4 lg:p-8 ">
          <div className="flex items-start mb-5 lg:mb-8">
            <button
              className="bg-[#912FB2] text-white p-2  mr-4"
              onClick={handleReturnClick2}
              id="return_1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </button>
            <div>
              <h3 className="text-2xl lg:text-3xl font-bold">
                Pay Tuition Fees
              </h3>
              <div className="my-8 w-full lg:w-1/2">
                <p className="text-gray-200">
                  Invest in education without borders. Join our platform and
                  experience the convenience and peace of mind that comes with
                  managing international school fees effortlessly. Experience
                  swift and reliable payment processing, ensuring that fees are
                  transferred securely and promptly to the educational
                  institution.
                </p>
              </div>
              <div className="flex">
              <GetStartedButton setShowWaitlist={setShowWaitlist}  />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse lg:flex-row w-full pt-4">
          <div className="flex items-center w-full lg:w-1/2 justify-center">
            <img
              src="/images/pay_tuition_mockup.png"
              className=""
              alt="send_money"
            />
          </div>
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl my-5 font-bold">How it works</h2>
            <div className="mb-5">
              <div className="bg-[#FDEBFF] rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Add Your Student Profile
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Enter details about the student for whom you're paying fees,
                    including their name, the school they're attending, and
                    possibly their student ID number.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF] rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Link a Payment Method
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Add your preferred payment method. This could be a bank
                    account, debit card, or your digital wallet.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF] rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">Make a payment</div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Search for the school you're paying fees to, using the app's
                    search function, confirm the breakdown of fees due (tuition,
                    borading, etc.) and make payment.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF]  rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">Payment Receipt</div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Upon successful completion user can download a digital
                    receipt for every transaction. This receipt is important for
                    your records and may be needed for future reference or to
                    resolve any disputes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col lg:flex-row lg:justify-between -mx-4 lg:mx-10 mt-20"
        id="service_3"
      >
        <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0" id="transfer">
          <div className="h-full text-left my-5 lg:my-20 relative">
            <h3 className="text-2xl lg:text-5xl text-[#452FAA] font-bold lg:text-start text-left md:text-center fs-2 mb-5 lg-mb-10">
              Send money across <br />
              the globe
            </h3>
            <p className="mb-5 lg:mb-8 text-xl lg:text-lg font-semibold text-gray-600">
              we understand the importance of hassle-free and secure
              international money transfers. Our platform ensures swift,
              reliable, and cost-effective transfers, connecting....
            </p>
            <button
              href=""
              className="text-[#452FAA]  font-bold"
              onClick={handleLearnMoreClick3}
            >
              Learn More
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4 mb-3 md:mb-8 lg:mb-0">
          <div className="h-full text-center relative">
            <img
              src="/images/acrosstheglobe.jpg"
              alt="Service Image"
              className="f w-full rounded-2xl mx-auto"
            />
          </div>
        </div>
      </div>

      <div className="hidden mt-8" id="service_details_3">
        <div className="bg-[#912FB2] text-white p-4 lg:p-8 ">
          <div className="flex items-start mb-5 lg:mb-8">
            <button
              className="bg-[#912FB2] text-white p-2  mr-4"
              onClick={handleReturnClick3}
              id="return_3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </button>
            <div>
              <h3 className="text-2xl lg:text-3xl font-bold">
                Send Money across the globe
              </h3>
              <div className="my-8 w-full lg:w-1/2">
                <p className="text-gray-200">
                  We understand the importance of hassle-free and secure
                  international money transfers. Our platform ensures swift,
                  reliable, and FREE transfers, connecting you to loved ones and
                  businesses globally. Whether it's supporting family abroad,
                  conducting business transactions, or simply managing your
                  international finances, RenderFX provides the bridge you need.
                  Sign up now and experience the ease of sending money globally
                  with confidence.
                </p>
              </div>
              <div className="flex">
              <GetStartedButton setShowWaitlist={setShowWaitlist}  />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse lg:flex-row w-full pt-4">
          <div className="flex items-center w-full lg:w-1/2 justify-center">
            <img
              src="/images/sendmoney_mockup.png"
              className=""
              alt="send_money"
            />
          </div>
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl my-5 font-bold">How it works</h2>
            <div className="mb-5">
              <div className="bg-[#FDEBFF]  rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">Account Setup</div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Download the app on App Store or Google Play and create an
                    account. Provide your personal information such as name,
                    address, email, and phone number for identity verification.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF] rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Initiating Transfer
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    To send money, selects the 'Send Money' option in the app,
                    enters the recipient's details and enter the amount of money
                    you wish to send
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF]  rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Processing Transaction
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Once the you confirm the payment, your account is debited
                    and the recipient’s account credited all in less than a Min.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bg-[#FDEBFF]  rounded-lg shadow-md p-6 w-full lg:w-5/6">
                <div className="">
                  <img src="/images/moneys.png" className="mb-5" alt="" />
                  <div className="text-xl font-semibold">
                    Confirmation and Receipt
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600">
                    Upon successful completion, the recipient receive
                    notification and transaction receipt is downloaded.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWaitlist && (
        <WaitlistPopUp
          setShowWaitlist={setShowWaitlist}
          showWaitlist={showWaitlist}
        />
      )}
    </div>
  );
};

export default OurServices;

// import React, { useState } from "react";

// const OurServices = () => {
//   const [serviceDetails, setServiceDetails] = useState([
//     { id: 1, shown: false },
//     { id: 2, shown: false },
//     { id: 3, shown: false },
//   ]);

//   const toggleServiceDetails = (id) => {
//     setServiceDetails(
//       serviceDetails.map((service) =>
//         service.id === id ? { ...service, shown: !service.shown } : service
//       )
//     );
//   };

//   return (
//     <div className="px-4 lg:px-0 py-12">
//       <div className="text-center mb-12">
//         <h2 className="text-3xl lg:text-5xl text-[#452FAA] font-bold">
//           Our Services
//         </h2>
//       </div>
//       {services.map((service) => (
//         <div key={service.id} className="flex flex-col lg:flex-row lg:justify-between -mx-4 lg:mx-10">
//           <div className={`w-full lg:w-1/2 px-4 mb-8 lg:mb-0 ${serviceDetails[service.id - 1].shown ? 'hidden' : ''}`}>
//             <div className="h-full text-left my-5 lg:my-20 relative">
//               <h3 className="text-3xl lg:text-5xl text-[#452FAA] font-bold fs-2 mb-5 lg:mb-10">
//                 {service.title}
//               </h3>
//               <p className="mb-5 lg:mb-8 text-m lg:text-lg text-gray-700">
//                 {service.description}
//               </p>
//               <button href="#" className="text-[#452FAA]  font-bold" onClick={() => toggleServiceDetails(service.id)}>
//                 Read More
//               </button>
//             </div>
//           </div>
//           <div className={`w-full lg:w-1/2 px-4 mb-8 lg:mb-0 ${serviceDetails[service.id - 1].shown ? '' : 'hidden'}`}>
//             <div className="bg-[#912FB2] text-white p-4 lg:p-8 ">
//               <div className="flex items-start mb-5 lg:mb-8">
//                 <button className="bg-[#912FB2] text-white p-2  mr-4" onClick={() => toggleServiceDetails(service.id)}>
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 24 24"
//                     strokeWidth={1.5}
//                     stroke="currentColor"
//                     className="w-6 h-6"
//                   >
//                     <path
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
//                     />
//                   </svg>
//                 </button>
//                 <div>
//                   <h3 className="text-2xl lg:text-3xl font-bold">
//                     {service.title}
//                   </h3>
//                   <div className="my-8 w-full lg:w-1/2">
//                     <p className="text-gray-200">
//                       {service.details}
//                     </p>
//                   </div>
//                   <div className="flex">
//                     <button className="bg-[#231BB5] text-white py-2 px-4 rounded mr-4">
//                       Get Started
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// const services = [
//   {
//     id: 1,
//     title: "Buy and Sell Currencies",
//     description: "We believe in connecting people worldwide through fair and secure currency exchange. Our peer-to-peer (P2P) foreign exchange service offers a revolutionary...",
//     details: "We believe in connecting people worldwide through fair and secure currency exchange. Our peer-to-peer (P2P) foreign exchange service offers a revolutionary way to Buy and Sell currencies directly between individuals, making your transactions seamless and cost-effective. Ready to exchange currencies with ease and confidence? Sign up today and start your journey towards seamless global transactions",
//   },
//   {
//     id: 2,
//     title: "Pay Tusion Fees",
//     description: "Invest in education without borders. Join our platform and experience the convenience and peace of mind that comes with managing international...",
//     details: "Invest in education without borders. Join our platform and experience the convenience and peace of mind that comes with managing international school fees effortlessly. Experience swift and reliable payment processing, ensuring that fees are transferred securely and promptly to the educational institution.",
//   },
//   {
//     id: 3,
//     title: "Send money across the globe",
//     description: "we understand the importance of hassle-free and secure international money transfers. Our platform ensures swift, reliable, and cost-effective transfers, connecting....",
//     details: "We understand the importance of hassle-free and secure international money transfers. Our platform ensures swift, reliable, and FREE transfers, connecting you to loved ones and businesses globally. Whether it's supporting family abroad, conducting business transactions, or simply managing your international finances, RenderFX  provides the bridge you need. Sign up now and experience the ease of sending money globally with confidence.",
//   },
// ];

// export default OurServices;
