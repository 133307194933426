import React from 'react'
import data from './data.js'
import Accordion from './Accordion.jsx'

const Faqs = () => {
  
  return (
    <div id="faqs" className="w-full bg-[#FCF7FF] py-8">
      <h1 className=" text-[#452FAA] lg:text-center text-center font-bold text-3xl lg:text-[46px] mb-6">Frequently Asked Questions</h1>
        {
          data.map((item, i) => (
           <Accordion item = {item} i = {i} />
          ))
        }
     
    </div>
  )
}

export default Faqs