import React, { useEffect, useState } from 'react';
import GlobalApi from '../Api/GlobalApi';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { Spinner } from 'flowbite-react';




const PrivacyPolicy = () => {

    const [policies, setPolicies] = useState([])
    const [isLoading, setIsLoading] = useState(false);

     
    //  get businessLists from API
     useEffect(()=>{
        setIsLoading(true)
        getPrivacyPolicyData();
     },[])
     const getPrivacyPolicyData =()=>{
         GlobalApi.getPrivacyData().then(resp=>{
            setPolicies(resp.privacies)
            setIsLoading(false);
         })
     }
  return (
    <div style={{marginTop:"120px"}}>
          <div>
             <div>
                {isLoading && (
               <div className='p-2 text-5xl   text-center'>
               <Spinner color="purple" aria-label="Purple spinner example" size="xl"/>
             </div>
            )}
                </div>
               {policies.map((policy)=>(
                  <div key={policy.id}>
                      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-1 p-4'>
                          <div className='text-2xl font-bold text-center'>
                             {policy.name}
                          </div>
                          <div className='pt-16 text-lg lg:pl-12 mb-20 lg:text-1xl leading-10' >
                             <RichText  content={policy?.description.raw.children}  />
                          </div>
                      </div>  
                  </div>
               ))}
          </div>
     </div>
  )
}

export default PrivacyPolicy