import React from 'react'
import TopMost from '../components/topmost/TopMost'
import OurFeatures from '../components/OurFeatures'
import OurServices from '../components/OurServices'
import AboutUs from '../components/AboutUs'
import Benefits from '../components/Benefits'
import Faqs from '../components/faqs/Faqs'

const Home = () => {
  return (
    <div style={{marginTop:"70px"}}>
         <TopMost  />
         <OurFeatures />
         <OurServices />
         <AboutUs  />
         <Benefits  />
         <Faqs  />
    </div>
  );
};

export default Home;
