import React, { useState } from 'react'
import WaitlistPopUp from './WaitlistPopUp';
import WaitlistButton from './WaitlistButton';

const AboutUs = () => {
  const [showWaitlist, setShowWaitlist] = useState(false);

  return (
    <div id='about-us'>
       <div className='grid grid-cols-1 lg:grid-cols-1'>
           <div className='p-4 px-10 bg-gray-100'>
               <p className='font-bold text-3xl text-indigo-700 text-center mb-8'>About Us</p>
               <p className='  text-xl font-semibold text-gray-600 lg:text-start text-left md:text-center mb-8 '>
                   At RenderFX, we are committed to simplifying global financial transactions, empowering individuals 
                   and businesses to navigate the complexities of international finance seamlessly. Our aim is to create a digital
                   currency market place that is secured, accessible and transparent where users can buy, sell and swap currencies at their
                   desired exchange rate. <br /><br />
                   Whether you are an individual seeking convenient currency exchange, a parent managing international school fees,
                   business owner conducting global transactions, or someone looking for secure virtual payment solutions, RenderFX is your
                   all-in-one financial Companion.
               </p>
               <p className=" flex items-center justify-center mt-4s  ">
              <WaitlistButton setShowWaitlist={setShowWaitlist} />
            </p>
           </div>
       </div>
       {showWaitlist && (
        <WaitlistPopUp
          setShowWaitlist={setShowWaitlist}
          showWaitlist={showWaitlist}
        />
      )}
    </div>
  )
}

export default AboutUs