import Navbar from "./components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import TagManager from 'react-gtm-module';
import { useEffect } from "react";



function App() {

  useEffect(() => {
    TagManager.initialize({ gtmId: 'G-NVFENDNBS1' });
    }, []);

  return (
    <Router>
       <Navbar  />
       <Routes>
         <Route path="/"  element={<Home />}  />
          <Route path="/contact"  element={<Contact  />}  />
          <Route path="/privacy"  element={<PrivacyPolicy  />}  />
          <Route path="/terms"  element={<Terms />}  />

       </Routes>
       <Footer  />
     </Router>
  );
}

export default App;
