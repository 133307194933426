import React from 'react'
import { BsLinkedin } from "react-icons/bs"
import { AiFillInstagram } from "react-icons/ai"
import { AiFillFacebook } from "react-icons/ai"
import { FaSquareXTwitter } from "react-icons/fa6"
import { BsTwitterX } from "react-icons/bs";
import { Link } from 'react-router-dom'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='font-montserrat  '>
      <section className='bg-[#777777] px-8 py-10 lg:px-16 lg:py-16
          grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4
          gap-8 lg:gap-12 
          text-[#F3F3F3]'> 
        <div>
          <img src="/images/Logo-footer.png" alt="logo-footer" width={130} />
          <p className='text-sm mt-2'>A financial technology company, not a bank.</p>
          <p className='my-3 font-medium'>Follow us</p>
          <ul className='flex gap-5 cursor-pointer'>
           <a href='https://www.linkedin.com/company/renderfx-ltd/' rel='noreferrer' target='_blank'> <BsLinkedin size={24}/></a>
            <a href='https://www.instagram.com/renderfxp2p/' rel='noreferrer' target="_blank"><AiFillInstagram size={24} /></a>
            <a href='https://www.facebook.com/profile.php?id=61554351697847' rel='noreferrer' target="_blank"> <AiFillFacebook size={24} /></a>
            <a href='https://twitter.com/RenderfxLtd' rel='noreferrer' target='_blank'><FaSquareXTwitter size={24} /></a>
          </ul>
        </div>
       
        <div className='flex justify-between flex-wrap'>
        <div className='mr-20 mb-10 md:mb-0 lg:mb-0 xl:mb-0'>
          <h3 className='text-xl font-semibold'>Services</h3>
          <ul className='mt-4 flex text-[#E2E2E2] flex-col gap-3 text-sm font-medium cursor-pointer'>
            <li >
                <a href="#exchange">
                   P2P Foreign Currency Exchange
                </a> 
            </li>
            <li>
              <a href='#tuition'> 
                International School Fees Payment
              </a>
              </li>
            <li>
               <a href='#transfer'>
                  International money transfer
                </a>
              </li>
              <li>
                 <a href='#render'>
                   Visa and Immigration Fee Payments
                 </a>
                </li>

          </ul>
        </div>
        </div>

        <div>
          <h3 className='text-xl font-semibold '>Company</h3>
          <ul className='mt-4 flex text-[#E2E2E2] flex-col gap-3 text-sm font-medium cursor-pointer'>
            <li>
              <a href='#about-us'>
                 About us
              </a>
            </li>
            {/* <li>Blog</li> */}
            <li>
              <a href='/contact'>
                Contact us
              </a>  
            </li>
            <li>
              <a href='#faqs'>
                FAQs
              </a>
            </li>
          </ul>
        </div>

        <div>
          <p className='text-xl font-semibold'>Legal</p>
          <ul className='flex  cursor-pointer mt-4'>
               <li  className='text-sm font-medium '>
                 <a href='/privacy'>
                    Privacy Policy
                 </a>
               </li>
          </ul>
          <ul className='flex mt-4 cursor-pointer '>
               <li   className=' text-sm font-medium '>
                 <a href='/terms'>
                    Terms & Conditions 
                 </a>
               </li>
          </ul>
        </div>   
      </section>

      <section className='bg-[#777777] px-8 py-10 lg:px-16 lg:py-14 pt-32 flex flex-col gap-5 lg:flex-row justify-between text-sm text-[#F9F9F9]'>
          <p className='lg:w-[60%] text-[12px] text-[#F9F9F9]'>RenderFX LTD, with company No. 15181989, is a limited liability company established under the laws of the United Kingdom whose principal office is located at 71 - 75 Shelton Street Covent Garden London WC2H 9JQ</p>
          <span className='font-medium'>
            &#169; {currentYear} Render FX. All rights reserved.
          </span>
      </section>
      <section className='bg-[#FFFFFF] py-12 lg:py-20'>
        <div className='flex mx-auto justify-center'>
          <BsTwitterX color='#ABABAB' size={24} />
        </div>
      </section>
    </footer>
  )
}

export default Footer