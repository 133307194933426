import React from 'react';
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { FaArrowTrendDown } from "react-icons/fa6";
import { AiOutlineGlobal } from "react-icons/ai";


const OurFeatures = () => {
  return (
    <div className='bg-indigo-700  ' >
        <div className='text-bold text-3xl text-center font-bold text-white p-10'> 
           Our Features
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4  p-10'>
            <div className='text-center' >
              <p className='flex items-center justify-center'>
                 <IoShieldCheckmarkOutline  size={70} color="white"  />
              </p>
                <p className='font-semibold mt-4 text-2xl text-white'>Security</p>
                <p className='text-2xl  text-white   font-light mt-4'>Bank-level security ensures your transactions are safe and protected.</p>
            </div>
            <div className='text-center' >
              <p className='flex items-center justify-center'>
                 <FaArrowTrendDown size={70}  color="white"  />
              </p>
                <p className='font-semibold mt-4 text-2xl text-white'>Low Fees</p>
                <p className='text-2xl  text-white   font-light mt-4'>Enjoy competitive rates and minimal fees for all transactions.</p>
            </div>
            <div className='text-center' >
              <p className='flex items-center justify-center'>
                 <AiOutlineGlobal  size={70} color="white"  />
              </p>
                <p className='font-semibold mt-4 text-2xl text-white'>Accessibility</p>
                <p className='text-2xl  text-white  font-light mt-4'>Access our services from anywhere, anytime , facilitating global transactions</p>
            </div>

        </div>
    </div>
  )
}

export default OurFeatures