import React, { useState, useRef } from "react";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const form = useRef();
  const [isSubmitted, setSubmitted] = useState(false);
  const [displayText, setDisplayText] = useState(
    "Thank you for contacting us, we will get back to you soon!"
  );
  const [isVerified, setVerified] = useState(false);

  const handleVerification = (response) => {
    // The response parameter contains the reCAPTCHA token
    if (response) {
      setVerified(true);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2no6rw5",
        "template_xsdsxtg",
        form.current,
        "8OyoU-4zfsEsXtlA4"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <section className="w-full mt-[100px]">
      <div className="h-full">
        {isSubmitted && (
          <div className="text-lg cursor-pointer   items-center font-semibold text-blue-700">
            <p className="text-sm p-2">{displayText}</p>
          </div>
        )}
        <div className="flex flex-col md:flex-row justify-between xl:justify-center xl:gap-[200px] items-center p-0 md:p-[30px] min-[930px]:p-[70px]">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="max-w-[485px] mb-[70px] md:mb-0"
          >
            <h1 className="text-3xl font-bold font-montserrat text-[#452FAA]">
              Contact Us
            </h1>
            <p className="font-semibold my-10">
              Got any question un-answered on Render FX?
            </p>
            <div className="flex flex-col gap-[30px]">
              <div className="flex flex-col gap-4">
                <label htmlFor="fullname" className="text-[#565656]">
                  Full name
                </label>
                <input
                  type="text"
                  name="user_name"
                  required
                  className="border-[1.5px] border-[#d9d9d9] bg-[#fbfbfb] rounded-[0.3rem]"
                />
              </div>
              <div className="flex flex-col gap-4">
                <label htmlFor="email" className="text-[#565656]">
                  Email address
                </label>
                <input
                  type="email"
                  name="user_email"
                  required
                  className="border-[1.5px] border-[#d9d9d9] bg-[#fbfbfb] rounded-[0.3rem]"
                />
              </div>
              <div className="flex flex-col gap-4">
                <label htmlFor="email" className="text-[#565656]">
                  Your question
                </label>
                <textarea
                  required
                  className="border-[1.5px] border-[#d9d9d9] bg-[#fbfbfb] rounded-[0.3rem] h-[150px] resize-none"
                  name="message"
                ></textarea>
              </div>
              <div>
                <ReCAPTCHA
                  sitekey="6Lfzd5MpAAAAAJTxzEESE7guohKaTFdogVA-YFrV"
                  onChange={handleVerification}
                />
              </div>
              <button
                type="submit"
                disabled={!isVerified}
                className={`${
                  isVerified ? "bg-[#452FAA]" : "bg-[#898787]"
                } text-sm md:text-xl text-[#FAFAFA]  px-[20px] py-[10px] md:px-[30px] md:py-2 self-start rounded-md`}
              >
                Send question
              </button>
            </div>
          </form>

          <div className="relative bg-[#452FAA] w-full md:w-[400px] md:h-[300px] lg:w-[500px] lg:h-[400px] py-11 pl-[20px] md:pl-[60px] flex gap-[30px] flex-col justify-center">
            <div className="absolute hidden md:block w-[70px] h-[70px] top-[-35px] left-[-35px] bg-[#E4CCFF]"></div>

            <h1 className="text-3xl font-bold font-montserrat text-white">
              Info
            </h1>
            {/* <div className="text-[#fafafa] flex items-center gap-[1rem]">
              <MdOutlinePhone className="text-[40px]" />
              <p className="text-xl">+234 **********</p>
            </div> */}
            <div className="text-[#fafafa] flex items-center gap-[1rem]">
              <MdOutlineEmail className="text-[40px]" />
              <p className="text-xl">contact@renderfx.co.uk</p>
            </div>
            {/* <div className="text-[#fafafa] flex items-center gap-[1rem]">
              <HiOutlineOfficeBuilding className="text-[40px]" />
              <p className="text-xl">+234 **********</p>
            </div> */}
          </div>
        </div>
        <div className="fixed inset-0 left-auto hidden md:block -z-10 w-[200px] xl:w-[25%] h-full bg-[#E4CCFF]"></div>
      </div>
    </section>
  );
};

export default Contact;
