import { request, gql } from 'graphql-request';

const MASTER_URL = "https://api-eu-west-2.hygraph.com/v2/clttelqpo06d507uwz8xx9g21/master";

const getPrivacyData = async()=>{
    const query = gql`
    query Privacy {
        privacies {
          id
          name
          description {
            raw
          }
        }
      }
   `
      const result = await request(MASTER_URL, query)
      return result
}


const getTermsAndConditions = async()=>{
    const query = gql`
    query Term  {
        terms {
          id
          name
          description {
            raw
          }
        }
      }
   `
      const result = await request(MASTER_URL, query)
      return result
}


export default{
    getPrivacyData,
    getTermsAndConditions
}


